




export default {
  beforeCreate() {
    window.location.replace("https://forms.gle/c7vPZctRPFEJUNc18");
  },
};
