

























export default {
  mounted() {
    const screenHeight = window.innerHeight;
    const appHeight = document.getElementById('app').clientHeight;

    if (appHeight < screenHeight) {
      console.log(document.getElementById('innovazione'));
      const a = document.getElementById('innovazione');
      a.style.minHeight = (a.clientHeight + (screenHeight - appHeight)) + 'px';
    }
  },
};
