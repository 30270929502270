



















































































































export default {
  data() {
    return {
      activeTab: 'storia',
      labelRight: -165,
      menuShown: false,
    };
  },
  mounted() {
    const screenHeight = window.innerHeight;
    const appHeight = document.getElementById('app').clientHeight;

    if (appHeight < screenHeight) {
      const a = document.getElementById('terapia');
      a.style.minHeight = (a.clientHeight + (screenHeight - appHeight)) + 'px';
    }
  },
  methods: {
    selectTab(tab: string) {
      this.activeTab = tab;

      if (window.innerWidth < 767) {
          this.menuToggle();
      }
    },
    showHideLabel() {
      if (this.labelRight === 0) {
        this.labelRight = -165;
      } else {
        this.labelRight = 0;
      }
    },
    menuToggle() {
      const ul = (document.getElementsByClassName('responsive-sub-menu')[0] as HTMLElement);

      if (this.menuShown) {
        ul.style.position = 'absolute';
      } else {
        ul.style.position = 'static';
      }

      this.menuShown = !this.menuShown;
    },
  },
};
