












































































export default {
  data() {
    return {
      activeTab: 'storia',
      menuShown: false,
    };
  },
  mounted() {
    const screenHeight = window.innerHeight;
    const appHeight = document.getElementById('app').clientHeight;

    if (appHeight < screenHeight) {
      const a = document.getElementById('protesi');
      a.style.minHeight = (a.clientHeight + (screenHeight - appHeight)) + 'px';
    }
  },
  methods: {
    selectTab(tab: string) {
      this.activeTab = tab;

      if (window.innerWidth < 767) {
          this.menuToggle();
      }
    },
    menuToggle() {
      const ul = (document.getElementsByClassName('responsive-sub-menu')[0] as HTMLElement);

      if (this.menuShown) {
        ul.style.position = 'absolute';
      } else {
        ul.style.position = 'static';
      }

      this.menuShown = !this.menuShown;
    },
  },
};
