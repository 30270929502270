




export default {
  beforeCreate() {
    window.location.replace("https://forms.gle/hcBtDd5sDqAbF9CP8");
  },
};
