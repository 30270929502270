


































import firebase from 'firebase';

export default {
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    submitForm() {
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
        (user) => {
          this.$router.replace('area-pazienti');
        },
        (err) => {
          alert('ERROR' + err.message);
        },
      );
    },
  },
};
