














import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  data() {
    return {
      hideHeaderFooter: false,
    };
  },
  components: {
    Header,
    Footer,
  },
  watch: {
    $route() {
      if (this.$route.name === 'adminattivita') {
        this.hideHeaderFooter = true;
      } else {
        this.hideHeaderFooter = false;
      }
    },
  },
};
