















































import firebase from 'firebase';

export default {
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
      loading: false,
    };
  },
  methods: {
    submitForm() {
      this.loading = true;
      if (!this.email.includes('@')) {
        this.email = `${this.email}@admin.com`
      }

      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
        (user) => {
          this.$router.replace('area-pazienti');
          this.loading = false;
        },
        (err) => {
          alert('ERROR: ' + err.message);
          this.loading = false;
        },
      );
    },
  },
};
