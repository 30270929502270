




export default {
  beforeCreate() {
    window.location.replace("https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAMAAALUhaNUODJSQzZYWFZIUzNHQllERkowMTY5S01KMy4u");
  },
};
