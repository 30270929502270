














































































































export default {
  data() {
    return {
      showSecondNav: false,
    };
  },
  mounted() {
    if (this.$route.name === 'home') {
      this.showSecondNav = false;
    } else {
      this.showSecondNav = true;
    }
  },
  watch: {
    $route() {
      if (this.$route.name === 'home') {
        this.showSecondNav = false;
      } else {
        this.showSecondNav = true;
      }
    },
  },
};
