










































export default {
    mounted() {
        setTimeout(() => {
            const screenHeight = window.innerHeight;
            const appHeight = document.getElementById('app').clientHeight;
            const a = document.getElementById('h1-home');
            a.style.minHeight = (a.clientHeight + (screenHeight - appHeight)) + 'px';
        }, 1);
    },
};
